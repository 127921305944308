import React, { Fragment, useState, useRef, useEffect, useMemo } from "react";
import { Popup, CircleMarker, LayerGroup, Polygon, useMap, useMapEvents, Pane } from "react-leaflet";
import PhotoMarker from "./PhotoMarker";
import { Dialog } from "@headlessui/react";

import * as config from "../../../config";


const CarouselContent = (props) => {
    return (
        <>
            {
                props.data.features.map((photoData) => {
                    return (<PhotoItem key={`${photoData.id}_photo`} bounds={props.bounds} photoData={photoData} />);
                })
            }
        </>
    );
};

const PhotoItem = (props) => {

    const photoPoint = useMemo(() => props.photoData.geometry.coordinates[0][0].slice().reverse(), [props.photoData]);
    const componentRef = useRef();


    const [showLoS, setShowLoS] = useState(false);
    const [isHighlighted, setHighlight] = useState(false);

    function decideDrawPhotoContent(photoPoint, bounds) {
        return bounds.contains(photoPoint);
    }

    function onClick() {
        componentRef.current.scrollIntoView({ behavior: "smooth" });
        setHighlight(true);
        setTimeout(() => {
            setHighlight(false);
        }, 1000);
        setShowLoS(!showLoS);
    }

    return (
        <>
            <PhotoMarker layerData={props.photoData} photoPoint={photoPoint} showLoS={showLoS} onClick={onClick} />
            {decideDrawPhotoContent(photoPoint, props.bounds) &&
                <div className="mb-6 pr-4 snap-start h-full w-1/3 md:w-full flex-shrink-0" ref={componentRef}  >
                    <PhotoContent
                        photoName={props.photoData.properties.Photos}
                        photoPoint={photoPoint}
                        layerData={props.photoData}
                        onMouseOver={() => setShowLoS(true)}
                        onMouseLeave={() => setShowLoS(false)}
                        highlight={isHighlighted}
                    />
                </div>
            }
        </>
    );
};

const PhotoContent = (props) => {

    const map = useMap();
    const [isOpen, setIsOpen] = useState(false);
    const photoURL = `${config.qgis_server_url.img}insecure/default/plain/local://${props.photoName}`;
    const description = `${props.layerData.properties.Descrip}`;
    const ref = `${props.layerData.properties.Ref}`;
    const source = `${props.layerData.properties.Source}`;

    function openModal(url) {
        //SetPhotoURL(url);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <img
                className={`h-64 w-full hover:h-[512px] object-cover object-center rounded-2xl cursor-move ${props.highlight == true ? "border-2 border-sky-600" : ""}`}
                src={photoURL}
                onClick={openModal}
                onMouseOver={props.onMouseOver}
                onMouseLeave={props.onMouseLeave}
            />
            <PhotoModal isOpen={isOpen} onClose={closeModal} url={photoURL} info={{ description: description, ref: ref, source: source }} />
        </>
    );
};

const PhotoModal = (props) => {

    let initialFocus = useRef();

    return (
        <Dialog initialFocus={initialFocus} open={props.isOpen} as="div" className="fixed inset-0 z-[9999]" onClose={props.onClose}>
            <div className="h-full bg-black bg-opacity-25" />
            <div className="fixed inset-0 flex flex-col h-full items-center portrait:justify-center xs:justify-center overflow-y-auto ml-auto mr-auto">
                <div className="h-[80vh] landscape:max-w-[80]">
                    <Dialog.Panel className="max-w-max max-h-max transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <img className="cursor-move" ref={initialFocus} src={props.url} onClick={props.onClose} />
                        <div className="text-center">
                            <p className="font-bold">
                                {props.info.description}
                            </p>
                            <div className="">
                                <span>
                                    Author: {props.info.ref}
                                </span>
                                <br />
                                <span>
                                    Source: {props.info.source}
                                </span>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default CarouselContent;
