import React, { Fragment, useState, useRef, useEffect, useMemo } from "react";
import { Popup, CircleMarker, LayerGroup, Polygon, useMap, useMapEvents, Pane } from "react-leaflet";

import * as config from "../../../config";

const PhotoMarker = (props) => {

    const photoLoS = useMemo(() => props.layerData.geometry.coordinates[0].map((v) => { return v.slice().reverse(); }, [props.layerData]));
    const losShown = props.showLoS;
    let markerRadius = 5;

    function style(losShown) {

        let markerStyle = {
            opacity: 0.9,
            dashArray: "",
            lineCap: "butt",
            lineJoin: "miter",
            weight: 0.4,
            fill: true,
            fillOpacity: 0.8,
            interactive: true,
        };

        if (losShown) {
            markerStyle.color = "rgba(35, 35, 35, 0.8)";
            markerStyle.fillColor = "rgba(37, 143, 181, 1)";
            markerRadius = 10;
        }
        else {
            markerStyle.color = "rgba(35, 35, 35, 0.8)";
            markerStyle.fillColor = "rgba(23, 45, 54, 1)";
            markerRadius = 5;
        }

        return markerStyle;
    }

    return (
        <>
            <CircleMarker
                pane="photoPane"
                center={props.photoPoint}
                pathOptions={{ opacity: 0, fillOpacity: 0 }}
                radius={20}
                bubblingMouseEvents={false}
                eventHandlers={{
                    click: () => {
                        props.onClick();
                    },
                }}>
                <CircleMarker
                    pane="photoPane"
                    center={props.photoPoint}
                    pathOptions={style(losShown)}
                    radius={markerRadius}
                    bubblingMouseEvents={true}
                />
            </CircleMarker>
            <PhotoLoS hidden={!losShown} points={photoLoS} />
        </>
    );
};

const PhotoLoS = (props) => {

    function style() {
        return {
            opacity: 0.6,
            color: "rgba(35, 35, 35, 0.8)",
            dashArray: "",
            lineCap: "butt",
            lineJoin: "miter",
            weight: 0.4,
            fill: true,
            fillOpacity: 0.3,
            fillColor: "rgba(37, 143, 181, 1)",
            interactive: false,
        };
    }

    if (props.hidden !== true) {
        return (
            <Polygon pathOptions={style()} positions={props.points} />
        );
    }
    else {
        return null;
    }
};

export default PhotoMarker;
