import React from "react";
import { TileLayer, MapContainer, LayersControl, ScaleControl, ZoomControl } from "react-leaflet";
import * as config from "../config";

import BaseLayers from "../component/layers/BaseLayers";
import ConfiguredLayers from "../component/layers/ConfiguredLayers";
import LocationControl from "../component/components/LocationControl";
import FastTravelButtons from "../component/components/FastTravelButtons";
import PhotoSlider from "../component/components/PhotoSlider/PhotoSlider";

const MapComponent = () => {

    return (
        <MapContainer className="h-full" center={config.centerPosition} zoom={config.initialZoomLevel} scrollWheelZoom={true} maxZoom={config.maxZoomLevel} zoomControl={false}>
            <PhotoSlider/>
            <FastTravelButtons />
            <ScaleControl />
            <ZoomControl position="bottomright" />
            <LocationControl />
            <LayersControl>
            {/*********************************************************************/}
                <BaseLayers />
                <LayersControl.BaseLayer checked={false} name="Google Maps View">
                    <TileLayer
                        attribution='&copy; <a href="https://maps.google.com">Google Maps</a>'
                        url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        subdomains={["mt0", "mt1", "mt2", "mt3"]}
                        maxNativeZoom={config.maxNativeZoomLevel}
                        maxZoom={config.maxZoomLevel}
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer checked={false} name="Google Satellite View">
                    <TileLayer
                        attribution='&copy; <a href="https://maps.google.com">Google Maps</a>'
                        url='http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        subdomains={["mt0", "mt1", "mt2", "mt3"]}
                        maxNativeZoom={config.maxNativeZoomLevel}
                        maxZoom={config.maxZoomLevel}
                        opacity={0.7}
                    />
                </LayersControl.BaseLayer>
            {/*********************************************************************/}
                <ConfiguredLayers />
            </LayersControl>
            {/*********************************************************************/}
        </MapContainer>
    );
};

export default MapComponent;