import React, { Fragment, useState, useEffect, forwardRef } from "react";
import { ResizableBox } from "react-resizable";
import { Rectangle, useMap, useMapEvents } from "react-leaflet";
import L, { bounds } from "leaflet";
import "react-resizable/css/styles.css";
import { Dialog, Transition } from "@headlessui/react";
import WFSDataLayer from "../../layers/WFSDataLayer";
import CarouselContent from "./Carousel";

import * as config from "../../../config";

function isHorizontal(width, height) {
    if (width > height)
        return true;
    else
        return false;
}

const PhotoSlider = (props) => {
    const map = useMap();
    const mapEvents = useMapEvents({
        moveend: () => setBoundingBox(sliderWidth, sliderHeight),
        zoomend: () => setBoundingBox(sliderWidth, sliderHeight)
    });

    const [isOpen, setIsOpen] = useState(false);
    const { height, width } = useWindowDimensions();
    const [{ bounds, sliderWidth, sliderHeight }, setBounds] = useState({ bounds: calculateBoundingBox(0, height), sliderWidth: 0, sliderHeight: 0 });

    function openPanel() {
        setIsOpen(true);
    }

    function closePanel() {
        setIsOpen(false);
    }

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);

        return windowDimensions;
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    function calculateBoundingBox(sliderWidth, sliderHeight) {
        let swPoint = L.point(sliderWidth, height);
        let nePoint = L.point(width, 0);

        let swLatLng = map.containerPointToLatLng(swPoint);
        let neLatLng = map.containerPointToLatLng(nePoint);

        let bounds = L.latLngBounds(swLatLng, neLatLng);
        console.log(bounds);
        return bounds;
    }

    function setBoundingBox(sliderWidth, sliderHeight) {
        setBounds({ bounds: calculateBoundingBox(sliderWidth, sliderHeight), sliderWidth: sliderWidth, sliderHeight: sliderHeight });
    }

    if (isHorizontal(width, height)) {
        return (
            <>
                <PhotoSliderOpenButton onClick={openPanel} />
                <Transition.Root show={isOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 z-[1000] pointer-events-none" onClose={() => { return; }}>
                        <Resizeable width={width} height={height} onResize={setBoundingBox}>
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-300"
                                enterFrom="translate-y-full md:-translate-x-full md:translate-y-0"
                                enterTo="translate-y-0 md:translate-x-0"
                                leave="transform transition ease-in-out duration-300"
                                leaveFrom="translate-y-0 md:translate-x-0 md:translate-y-0"
                                leaveTo="translate-y-full md:-translate-x-full md:translate-y-0"
                            >
                                <div className="w-screen max-h-sm md:h-full">
                                    <div className="w-full h-full md:flex md:flex-col bg-[#ecebeb] shadow-xl">
                                        <div className="flex flex-col h-full p-4">
                                            <PhotoSliderCloseButton onClick={closePanel} />
                                            <div className="photoCarousel overflow-y-hidden md:overflow-y-auto  md:overflow-x-hidden">
                                                <div className="flex flex-row md:flex-col snap-x md:snap-y snap-mandatory scroll-smooth">
                                                    <WFSDataLayer name={"Photographic_Angles"} url={config.qgis_server_url["map_bornova"]}>
                                                        <CarouselContent bounds={bounds} />
                                                    </WFSDataLayer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </Resizeable>
                    </Dialog >
                </Transition.Root >
            </>
        );
    }
    else {
        return <PhotoSliderOpenButton onClick={() => {}} />;
    }
};

const PhotoSliderOpenButton = (props) => {
    return (
        <>
            <div className="leaflet-control-container leaflet-top">
                <div className="relative leaflet-control-layers leaflet-control leaflet-bar w-30 h-30">
                    <a className="photo-button bg-[url('../public/photicon.png')] cursor-pointer hover:bg-slate-100`"
                        onClick={props.onClick}
                        aria-label="Open Photos Panel"
                        title="Open Photos"
                    >
                    </a>
                </div>
            </div>
        </>
    );
};

const PhotoSliderCloseButton = (props) => {
    return (
        <>
            <div className="flex place-items-end justify-end">
                <div className="h-7 relative right-[2px] flex justify-items-end">
                    <button
                        type="button"
                        className="bg-[#ecebeb] rounded-md text-gray-600 hover:text-gray-800 focus:ring-2 focus:ring-sky-600"
                        onClick={props.onClick}
                    >
                        X
                    </button>
                </div>
            </div>
        </>
    );
};

const Resizeable = (props) => {

    const boxWidth = props.width / 3;
    const boxHeight = props.height;

    function resizeFinished(e, data) {
        props.onResize(data.size.width, data.size.height);
    }

    if (isHorizontal(props.width, props.height)) {
        return (
            <>
                <ResizableBox
                    width={boxWidth}
                    height={boxHeight}
                    minConstraints={[props.width / 6, props.height]}
                    maxConstraints={[props.width / 2, props.height]}
                    axis="x"
                    handle={<ResizeHandle height={boxHeight} />}
                    handleSize={[10, 10]}
                    resizeHandles={["e"]}
                    className="fixed inset-0 flex flex-row justify-start pointer-events-auto"
                    onResizeStop={(e, data) => resizeFinished(e, data)}
                >
                    {props.children}
                </ResizableBox>
            </>
        );
    }
    else {
        return (
            <div className="fixed inset-0 flex flex-col-reverse md:flex-row justify-start pointer-events-auto">
                {props.children}
            </div>
        );
    }
};

const ResizeHandle = forwardRef(function ResizeHandle(props, ref) {
    const { handleAxis, ...restProps } = props;
    return (
        <div className={`h-full hover:cursor-col-resize handle-${handleAxis}`} ref={ref} {...restProps} >
            <div className={"flex justify-center items-center h-full w-4 relative right-4 bg-gray-300 hover:bg-gray-400"} >
                <span className={"text-center align-middle relative"}>
                    {"||"}
                </span>

            </div>
        </div>
    );
});



export default PhotoSlider;
